<template>
  <div class="staff">
    <div class="container">
      <form>
        <div class="person page">
          <div class="title">导语<span @click="gradeStandard">评分标准细则</span></div>
          <p class="tips">
            本次360°测评的意义在于：全面客观地反映“核心高管”候选人在日常工作当中的真实状态。为公司选出真正时刻弘扬公司核心价值观、助力公司发展，乐于奉献，技术和能力双优的的核心管理技术人才。本次360°测评的唯一要求：客观公正、真实反馈、不恶意诋毁。
          </p>  
        </div>

        <div class="page questionnaire">
          <div class="relation">
            <p>
              <span>核心高管被评人：
                <span class="blue">{{ personInfo.realname}}<em v-show="personInfo.aliasname">（{{personInfo.aliasname}}）</em>--{{personInfo.deptname}}</span>
              </span>
            </p>
            <div class="radio-title">你是被评价人的：<span class="blue">{{relation}}</span></div>
            <van-radio-group v-model="relation">
              <van-radio name="直接领导">直接领导</van-radio>
              <van-radio name="直接下属">直接下属</van-radio>
              <van-radio name="同部门同事">同部门同事</van-radio>
              <van-radio name="跨部门同事">跨部门同事</van-radio>
              <van-radio name="跨部门领导">跨部门领导</van-radio>
              <van-radio name="跨部门下属">跨部门下属</van-radio>
            </van-radio-group>
          </div>

          <van-divider class="item-title" content-position="center">价值观</van-divider>
          <div class="radio-title">1.该核心高管认同并积极践行公司的核心价值观，将自身前途与公司发展紧密结合，是公司的忠实拥护者。</div>
          <van-radio-group v-model="form.n1">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">工作态度</van-divider>
          <div class="radio-title">2.是遵守公司规章制度的表率，工作态度认真，有担当。</div>
          <van-radio-group v-model="form.n2">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">团队协作</van-divider>
          <div class="radio-title">3.处处尊重团队成员。在团队决策时，能充分尊重和重视团队成员提出的不同观点和意见，做到聚焦最佳的方案，灵活变通，达成共识。</div>
          <van-radio-group v-model="form.n3">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>
          <div class="radio-title">4.当团队工作出现问题时，首先想到的是反省自己的工作，以及如何解决团队问题，而不是追究谁的责任。</div>
          <van-radio-group v-model="form.n4">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>
          <div class="radio-title">5.真诚主动地给予团队伙伴或团队下属各方面的帮助，特别是新入团队的伙伴。能切实传授技能，帮助新队伙伴尽快融入团队。</div>
          <van-radio-group v-model="form.n5">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">工作实施</van-divider>
          <div class="radio-title">6.基于公司下达工作任务，能有效传达和合理分解，并对团队进行解读、落实，形成团队的短期工作计划，就团队工作结果对公司负责。</div>
          <van-radio-group v-model="form.n6">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>
          <div class="radio-title">7.合理且符合逻辑的对多个下级进行工作安排，对团队下级的工作能及时跟踪和有效指导。对综合能力不同的下属赋能也相对不同。</div>
          <van-radio-group v-model="form.n7">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">抗压力</van-divider>
          <div class="radio-title">8.能以平静的心态和情绪对待工作中出现的批评或挫折。在较大工作压力面前，保持客观冷静的观察力和判断力，主动调节团队氛围，积极传播正能量，从不抱怨、发牢骚。</div>
          <van-radio-group v-model="form.n8">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">沟通能力</van-divider>
          <div class="radio-title">9.善于与不同的人保持有效沟通。当处理冲突时能秉持公正客观的原则，善用沟通技巧，达成各方都满意的结果。</div>
          <van-radio-group v-model="form.n9">
            <van-radio name="5">完全符合</van-radio>
            <van-radio name="4">比较符合</van-radio>
            <van-radio name="3">一般</van-radio>
            <van-radio name="2">不符合</van-radio>
            <van-radio name="1">极度不符合</van-radio>
          </van-radio-group>

          <van-divider class="item-title" content-position="center">综合</van-divider>
          <div class="radio-title">10.该候选人是否符合你心目当中“核心高管”的形象？</div>
          <van-radio-group v-model="form.n10">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </div>

        <div class="submit" @click="submitStaff">提交问卷</div>
      </form>
    </div>

    <!-- 评分细则 -->
    <mt-popup class="gradeDialog" v-model="gradeVisible" position="center" style="width: 6.9rem">
      <i @click="gradeVisible = false" class="jym-close dialog-close"></i>
      <van-divider content-position="center" :style="{ color: '#1989fa', borderColor: '#1989fa'}">评分标准</van-divider>
      <p>请仔细阅读以下表格中的定义。</p>
      <table>
        <thead>
          <tr>
            <th width="100">选项</th>
            <th width="40">分数</th>
            <th>定义</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>完全符合</td>
            <td>5</td>
            <td>足够优秀且突出，已经超出公司对该岗位的员工要求，能主动践行公司核心价值观、并自觉很主动地帮助其他同事，可作为同岗位优秀的标杆。</td>
          </tr>
          <tr>
            <td>比较符合</td>
            <td>4</td>
            <td>日常工作表现满意，能胜任公司对该岗位的员工要求，能达到公司优秀员工的水平。偶尔会帮助其他同事。</td>
          </tr>
          <tr>
            <td>一般</td>
            <td>3</td>
            <td>工作态度良好，现阶段基本适合该岗位的员工要求，还可进一步提高。业务能力还不足以帮助其他同事。</td>
          </tr>
          <tr>
            <td>不符合</td>
            <td>2</td>
            <td>能力或意识此时还不能胜任岗位需要，有待提高。没有与其他同事分享的意识。</td>
          </tr>
          <tr>
            <td>极度不符合</td>
            <td>1</td>
            <td>能力或意识存在明显短板或缺陷，对待同事不友好。</td>
          </tr>
        </tbody>
      </table>
      <p>您需要回顾被评价的<span>“优秀员工/核心高管”</span>候选人在日常工作中的表现，评价他/她平时是否拥有和贯彻这些能力和行为，并选择相对应的选项。</p>
    </mt-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { MessageBox } from "mint-ui";
import { RadioGroup, Radio, Divider } from "vant";
import { staffVote } from "@api/user";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Divider)
export default {
  data() {
    return {
      relation: '',
      form: {
        n1: '',
        n2: '',
        n3: '',
        n4: '',
        n5: '',
        n6: '',
        n7: '',
        n8: '',
        n9: '',
        n10: ''
      },
      personInfo: {},
      staffList: [],
      manageList: [],
      loading: false,
      gradeVisible: false
    };
  },
  created() {
    this.personInfo = this.$route.query
  },
  methods: {
    // 问卷结果累加
    sum(arr) {
      return arr.reduce(function(prev, curr){
        return prev + curr;
      });
    },

    submitStaff() {
      let that = this
      let relation = that.relation
      let form = Object.values(that.form)

      // 必填提示
      if(!relation) {
        that.$dialog.error("与评价人关系必填！");
        return
      }
      for (let i in form) {
        if(!form[i]) {
          let n = Number(i) + 1
          that.$dialog.error("选项"+ n +"没有选择！");
          return
        }
      }

      let answerArr = form.slice(0, form.length -1).map(Number)
      let params = {
        type: 3,
        staff_id: that.personInfo.id,
        linkname: relation,
        answer: answerArr,
        is_yes: form[form.length -1],
        total: that.sum(answerArr)
      }

      MessageBox({
        title: "提示",
        message: "提交之后所有信息不可修改，确定提交？",
        showCancelButton: true,
      }).then((status) => {
        if (status === "confirm") {
          staffVote(params).then((res) => {
            that.$dialog.success(res.msg)
            setTimeout(()=> {
              that.$router.push(`/user/BackStaffEvaluation`)
            }, 800)
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
          });
        } else {
          return;
        }
      });
    },

    // 评分标准
    gradeStandard() {
      this.gradeVisible = true
    }
  }
};
</script>

<style scoped lang="scss">
.staff {
  .container {
    font-size: 0.26rem;
    padding: 0 .2rem .3rem .2rem;
    .page {
      background: white;
      border-radius: 8px;
      padding: .1rem .3rem .3rem .3rem;
      margin-top: .3rem;
      .tips {
        font-size: .26rem;
        text-indent: 2em;
        line-height: .4rem;
        color: #333;
        margin-bottom: .2rem;
      }
    }
    .person {
      font-size: .28rem;
      .title {
        font-size: .3rem;
        margin: .4rem 0 .1rem;
        position: relative;
        padding-left: .2rem;
        span {
          font-size: .26rem;
          float: right;
          color: #007bff;
        }
      }
      .title::before {
        content: '';
        position: absolute;
        width: .06rem;
        height:.3rem;
        top: .06rem;
        left: 0;
        background: #007bff;
      }
      .info {
        display: flex;
        justify-content: space-between;
        span {
          color: #007bff;
        }
      }
    }
    .questionnaire {
      font-size: .28rem;
      .relation {
        margin-top: .2rem;
        p {
          margin: .2rem 0;
        }
        .van-radio-group {
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          .van-radio {
            width: 50%;
            margin: .1rem 0;
          }
        }
      }
      .van-radio-group {
        margin: .2rem 0 .5rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: start;
        .van-radio {
          font-size: .27rem;
          width: 33.33%;
          margin: 0.1rem 0;
        }
      }
      .item-title {
        font-size: .3rem;
        font-weight: 500;
        color: #333;
        margin: .5rem 0 .2rem;
      }
      .radio-title {
        line-height: .42rem;
        margin: 0.1rem 0;
      }
    }
    textarea {
      border: 1px solid #000;
      border-radius: 0.12rem;
      width: 100%;
      height: 2rem;
      padding: 0.2rem;
    }
    .submit {
      display: block;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0;
      font-size: 0.32rem;
      background: #3bc4aa;
      text-align: center;
      border-radius: 0.12rem;
      color: white;
      margin-top: 0.5rem;
    }
    h4 {
      margin: 0.2rem 0;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 500;
    }
  }
  .gradeDialog {
    padding: 0 .2rem;
    p {
      color: #000;
      font-size: .26rem;
      line-height: .36rem;
      margin-bottom: .3rem;
    }
    table {
      border: 1px solid #ddd;
      margin-bottom: .3rem;
      thead {
        th {
          font-size: .26rem;
          text-align: center;
          line-height: .6rem;
        }
      }
      tbody {
        line-height: .32rem;
        font-size: .24rem;
        color: #73859f;
        tr {
          border-top: 1px solid #ddd;
          td {
            padding: .1rem;
          }
        }
      }
    }
  }
}
</style>